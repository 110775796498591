import React, { useState } from "react";
import "./login.css";
import { Form, Button, Container, Alert } from "react-bootstrap";
import kapnalogo from "../../Assets/kapnalogo.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form from reloading the page
    setError(null); // Clear any previous errors
    setSuccess(null); // Clear previous success messages

    const payload = {
      email, // User email from input
      password, // User password from input
    };

    try {
      // Send POST request to the API
      const response = await fetch(
        "https://lunarsenterprises.com:6012/apnastore/admin/login", // API URL
        {
          method: "POST", // POST method
          headers: {
            "Content-Type": "application/json", // Set headers for JSON body
          },
          body: JSON.stringify(payload), // Convert payload to JSON
        }
      );

      if (!response.ok) {
        // Check for response errors
        const errorData = await response.json();
        throw new Error(errorData.message || "Login failed");
      }

      // Parse successful response
      const data = await response.json();
      setSuccess("Login successful!");
      console.log("Login Response:", data); // Handle response data here
      localStorage.setItem("user_token", data.user_token);

      navigate("/dashboard");

      // Further actions, e.g., redirecting to dashboard
    } catch (err) {
      setError(err.message); // Display error message
    }
  };

  return (
    <div className="login-page">
      <Container className="login-container">
        <img className="kapnalogo" src={kapnalogo} alt="" />
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="login-label">Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              className="login-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Update email state
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="login-label">Password</Form.Label>
            <div className="password-input-container">
              <Form.Control
                type={showPassword ? "text" : "password"} // Toggle input type
                placeholder="Password"
                className="login-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)} // Update password state
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="eye-icon"
                onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
              />
            </div>
          </Form.Group>

          <div className="text-end mb-3">
            <a href="/forgetpassword" className="forgot-password">
              Forgot Password?
            </a>
          </div>

          <Button type="submit" className="login-button w-100">
            Login
          </Button>
        </Form>
      </Container>
    </div>
  );
}

export default Login;
