import React, { useState, useEffect } from 'react';
import { Button, Table, Dropdown, DropdownButton } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import axios from 'axios';
import "./userlist.css";

function Userlist() {
  const [users, setUsers] = useState([]);
  const [levelFilter, setLevelFilter] = useState('');
  const [districtFilter, setDistrictFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch users from the API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await axios.post('https://lunarsenterprises.com:6012/apnastore/list-users');
        if (response.data.result) {
          setUsers(response.data.list.map(user => ({
            name: user.u_name,
            phnNumber: user.u_mobile,
            userId: user.u_ref_id,
            level: user.u_position,
            district: user.u_district,
            totalAmount: Math.random() * 10000, // Mock totalAmount since it's not in the API
          })));
        } else {
          throw new Error(response.data.message || 'Failed to fetch data');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Filtered users based on selected filters
  const filteredUsers = users.filter(user => 
    (levelFilter ? user.level.toLowerCase() === levelFilter.toLowerCase() : true) && 
    (districtFilter ? user.district.toLowerCase() === districtFilter.toLowerCase() : true)
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col-3"><Sidebar /></div>
        <div className="col-9">
          <h3 className="my-3">User List</h3>

          {/* Filters */}
          <div className="d-flex justify-content-between mb-3">
            <div>
              <DropdownButton className="user-filter-button" title="Level" onSelect={setLevelFilter} id="level-filter">
                <Dropdown.Item eventKey="">All Levels</Dropdown.Item>
                <Dropdown.Item eventKey="beginner">Beginner</Dropdown.Item>
                <Dropdown.Item eventKey="intermediate">Intermediate</Dropdown.Item>
                <Dropdown.Item eventKey="advanced">Advanced</Dropdown.Item>
                <Dropdown.Item eventKey="retailer">Retailer</Dropdown.Item>
                <Dropdown.Item eventKey="distributor">Distributor</Dropdown.Item>
                <Dropdown.Item eventKey="regional level distributor">Regional Level Distributor</Dropdown.Item>
                {/* Add more levels if needed */}
              </DropdownButton>
            </div>
            <div>
              <DropdownButton className="user-filter-button" title="District" onSelect={setDistrictFilter} id="district-filter">
                <Dropdown.Item eventKey="">All Districts</Dropdown.Item>
                {users
                  .map(user => user.district.trim())
                  .filter((value, index, self) => self.indexOf(value) === index) // Unique districts
                  .map(district => <Dropdown.Item key={district} eventKey={district}>{district}</Dropdown.Item>)}
              </DropdownButton>
            </div>
          </div>

          {/* User Table */}
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-danger">Error: {error}</p>
          ) : (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>User ID</th>
                  <th>Level</th>
                  <th>District</th>
                  <th>Total Amount Achieved</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user, index) => (
                  <tr key={index}>
                    <td>{user.name}</td>
                    <td>{user.phnNumber}</td>
                    <td>{user.userId}</td>
                    <td>{user.level}</td>
                    <td>{user.district}</td>
                    <td>Rs.{user.totalAmount.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
}

export default Userlist;
